import Container from "../../components/Container";
export default function Documentation() {
    return (
        <Container>
            <h4>Welcome to SLT's Artificial Intelligence Playground. (AIP)</h4>



            <p>This is a tool where you can leverage the power of LLM to analyze, annotate or generate data in bulk from tsv or csv input. Let's go over each of the top navigation items:</p>



            <h5>Playground</h5>
            <ul>
                <li>This is where you can upload a csv or tsv file with your source and translations (targets). Each line/row is a group of source text and the corresponding transcription(s) separated by the csv or tsv delimiter.</li>
                <li>Note that the file should contain a header row.</li>
                <li>Once you've chosen your file and uploaded it, you'll be asked to map your rows to placeholders (see prompts section) and select the LLM you’d like to use. (Makes sure you have a valid API key set up in Settings for the respective LLM options to be available)</li>
            </ul>
            <h5>Jobs</h5>
            <ul>
                <li>This is used to download your results and also for checking the progress of past files you’ve processed.</li>
                <li>If any of the rows in your file fail for any reason, you can try clicking the “redo failed lines” button to try again.</li>
            </ul>
            <h5>Prompts</h5>
            <ul>
                <li>You will see some example prompts in your account, you can use them to test or modify them as per your needs. </li>
                <li>You can edit and add 'Prompts' here. These are instructions that tell the language model what it should do with the content. They will apply to each row of text in your .csv or .tsv file.</li>
                <li>When editing/adding a prompt:
                <ul>
                    <li>Name: the name of the prompt you are creating/editing</li>
                    <li>System: Imagine the expert or person you want to be doing this task. Put in a description here and the LLM will assume that persona while doing this task. (ex: You are a senior translation buyer evaluating test translations made by different candidate translators.)</li>
                    <li>Messages: These are instructions. Very specific descriptions of tasks you want the language model to perform. There are two kinds of messages (click the grey label to toggle between them):
                    <ul>
                        <li>User: Describe your instructions. Used to give direct, explicit instructions. Simply tell the system what operations it should perform, step by step. Be as specific as possible. If there are different responses you’d like to get for different situations, describe all of them to get consistent results. </li>
                        <li>Assistant: Show your instructions. Used to show how you would like the response to look like, without giving explicit instructions. Just show an example of a response you’d like to get when placed after a user message, and the system will try to figure out what you want it to do. You can provide multiple User/Assistant pairs before providing one last User message to execute the operation on your row of text. </li>
                        <li>Example of user-assistant usage for a sentiment analysis prompt example: <img width={'100%'} src={"/doc.png"} /></li>
                    </ul>
                    </li>
                </ul>
                </li>
                <li>Please keep in mind these message are sent for each row of data for the purposes of API calls and associated charges to you from the LLM you are using. </li>
                <li>You can add placeholders. These are variables you can use in your prompt.
                <ul>
                    <li>If you check “in file” that means that one of the columns of your input file will contain the value, you want assigned to this placeholder when that row is processed. (example: source content, target content) </li>
                    <li>If you do not check “in file” you’ll be prompted, when you upload your csv/tsv file, to enter in a value for this placeholder that will be used for every row in the file. </li>
                    <li>When using placeholders in your user messages or system messages, use double curly brackets to indicate they are placeholders.
                    <ul>
                        <li>Example of Prompt with placeholders: You are a helpful assistant specialized in customer satisfaction. For each given {'{{source}}'} in {'{{sourceLanguage}}'}, generate a Sentiment analysis annotation. Use the following annotation, according to the sentiment detected: Positive, Negative and Neutral.  </li>
                        <li>In this case you will need to add the following placeholders - source (in File because it will be a column) and sourceLanguage (Can leave in-file unchecked, if you want to specify it for each file at runtime. In-file will work better if each source is of a different language and you want to specify it in the file for each row of data) </li>
                    </ul>
                    </li>
                    <li>Do not use brackets when in the placeholder names. The brackets are just used to indicate placeholders in your prompt’s user and assistant messages. </li>
                </ul>
                </li>
            </ul>
            <h5>Settings</h5>
            <ul>
                <li>You won't be able to use this tool without your own API keys. You can enter them here. </li>
                <li>Note that these keys are saved in your local browser and is never saved on our backend. </li>
                <li>You don't need to click a save button. It is automatically saved in your browser the moment you enter it into the text box. </li>
                <li>You can also delete your account here by clicking the "Delete account" button at the bottom </li>
            </ul>
            <h5>About</h5>
            <ul>
                <li>Takes you back to this page! </li>
            </ul>






        </Container>
    );
};