import Container from "../../components/Container";
import {NavLink} from "react-router-dom";

export default function TermsOfConditions() {
    return (
        <Container>
                <a href="/signUp">&lt;-- Back to sign up</a>
                <hr/>
                <h4>Terms and Conditions of Use </h4>
                <p>These Terms and Conditions of Use ("Terms") govern your access to and use of AI Playground ("the
                        Software"), provided by Summa Linguae Technologies ("the Company"). By accessing or using the
                        Software, you agree to be bound by these Terms. If you do not agree to these Terms, please do
                        not access or use the Software. </p>
                <h5>1. Access to the Software </h5>
                <h5>1.1 License:</h5>
                <p>Subject to these Terms, the Company grants you a non-exclusive, non-transferable, revocable license
                        to access and use the AI Playground solely for your internal business purposes. </p>
                <h5>1.2 Registration:</h5>
                <p>In order to access certain features of the AI Playground, you may be required to register for an
                        account. You agree to provide accurate and complete information during the registration process
                        and to update such information to keep it accurate and current. </p>
                <h5>1.3 Security:</h5>
                <p>You are responsible for maintaining the security of your account credentials and for all activities
                        that occur under your account. You agree to notify the Company immediately of any unauthorized
                        use of your account or any other breach of security. </p>
                <h5>2. Use of the Software </h5>
                <h5>2.1 Acceptable Use: </h5>
                <p>You agree to use the AI Playground in compliance with all applicable laws and regulations and in
                        accordance with these Terms. You shall not use the AI Playground for any unlawful or
                        unauthorized purpose. </p>
                <h5>2.2 Restrictions:</h5>
                <p>You shall not (a) modify, adapt, or translate the AI Playground; (b) sublicense, lease, rent, or
                        otherwise transfer your rights to the AI Playground to any third party; (c) reverse engineer,
                        decompile, disassemble, or otherwise attempt to derive the source code of the AI Playground; (d)
                        interfere with or disrupt the integrity or performance of the AI Playground or the data
                        contained therein. </p>
                <h5>2.3 Responsibility:</h5>
                <p>Users provide their own LLM API keys for use with the AI Playground platform ("the Platform"). Users
                        are solely responsible for the usage of any API keys they provide, including the security and
                        confidentiality of those keys. </p>
                <h5>2.4 Usage and Costs:</h5>
                <p>Users acknowledge and agree that they are responsible for any costs, fees, or charges incurred as a
                        result of their use of their own API keys, including but not limited to data transmission fees,
                        usage charges, and any other expenses associated with accessing or using the Platform. </p>
                <h5>2.5 Indemnification:</h5>
                <p>Users agree to indemnify and hold harmless Summa Linguae Technologies and its affiliates, officers,
                        directors, employees, agents, and licensors from and against any claims, liabilities, damages,
                        losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or
                        related to the user's use of their API keys, including any misuse, unauthorized access, or
                        violation of these terms and conditions. </p>
                <h5>3. Intellectual Property </h5>
                <h5>3.1 Ownership:</h5>
                <p>The AI Playground, including all intellectual property rights therein, is and shall remain the
                        exclusive property of Summa Linguae Technologies. </p>
                <h5>3.2 Feedback: </h5>
                <p>If you provide any feedback, suggestions, or other information regarding the AI Playground
                        ("Feedback"), you hereby grant Summa Linguae Technologies a perpetual, irrevocable,
                        royalty-free, worldwide license to use and incorporate such Feedback into the AI
                        Playground. </p>
                <h5>4. Privacy </h5>
                <h5>4.1 Data Collection:</h5>
                <p>Summa Linguae Technologies may collect and use personal data and other information as described in
                        its <NavLink to={'/privacyPolicy'}>Privacy Policy</NavLink>. By accessing or using the AI Playground, you consent to the
                        collection and use of your data in accordance with the Privacy Policy. </p>
                <h5>4.2 Email and User-Uploaded Data: </h5>
                <p>In order to provide you with access to the AI Playground and its features, Summa Linguae Technologies
                        may collect and store your email address and data that you upload or submit while using the
                        platform. Summa Linguae Technologies will handle such data in accordance with its Privacy
                        Policy. </p>
                <h5>5. Disclaimer of Warranties </h5>
                <h5>5.1 As Is:</h5>
                <p>THE AI PLAYGROUND IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER
                        EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY LAW, SUMMA LINGUAE TECHNOLOGIES DISCLAIMS
                        ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                        AND NONINFRINGEMENT. </p>
                <h5>6. Limitation of Liability </h5>
                <h5>6.1 Exclusion:</h5>
                <p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL SUMMA LINGUAE TECHNOLOGIES BE LIABLE FOR
                        ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR
                        REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER
                        INTANGIBLE LOSSES, ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS TO OR USE OF THE AI
                        PLAYGROUND. </p>
                <h5>7. Governing Law </h5>
                <h5>7.1 Jurisdiction:</h5>
                <p>These Terms shall be governed by and construed in accordance with the laws of Canada without regard
                        to its conflicts of law principles. </p>
                <h5>8. Changes to the Terms </h5>
                <h5>8.1 Modification: </h5>
                <p>Summa Linguae Technologies reserves the right to modify or revise these Terms at any time in its sole
                        discretion. Your continued use of the AI Playground following the posting of any changes to
                        these Terms constitutes acceptance of those changes. </p>
                <h5>9. Termination </h5>
                <h5>9.1 Termination:</h5>
                <p>Summa Linguae Technologies may terminate or suspend your access to the AI Playground at any time,
                        with or without cause, and with or without notice. </p>
                <h5>10. Miscellaneous </h5>
                <h5>10.1 Entire Agreement: </h5>
                <p>These Terms constitute the entire agreement between you and Summa Linguae Technologies regarding your
                        use of the AI Playground and supersede all prior or contemporaneous agreements, representations,
                        warranties, and understandings. </p>
                <h5>10.2 Severability: </h5>
                <p>If any provision of these Terms is found to be unenforceable or invalid, that provision shall be
                        limited or eliminated to the minimum extent necessary so that these Terms shall otherwise remain
                        in full force and effect. </p>
        </Container>
    );
};