import Contact from "./Contact";
import Documentation from "./Documentation";
import PrivacyPolicy from "./PrivacyPolicy";

export default function About() {
    return (
        <>
            <Contact/>
            <Documentation/>
        </>
    );
};