import Container from "../../components/Container";

export default function PrivacyPolicy() {
        return (
            <Container>
                <a href="/signUp">&lt;-- Back to sign up</a>
                <hr/>
                    <h4>Privacy Policy</h4>
                    <p>This Privacy Policy describes how Summa Linguae Technologies ("the Company", "we", "our", or
                        "us") collects, uses, and protects the personal information you provide when accessing or using
                        the AI Playground platform ("the Platform"). </p>
                    <h5>1. Information We Collect </h5>
                    <h5>1.1 Information You Provide:</h5>
                    <p>When you register for an account on the Platform, we collect personal information you voluntarily
                        provide, such as your email address, company name, and company role. </p>
                    <h5>2. How We Use Your Information </h5>
                    <h5>2.1 Providing Services:</h5>
                    <p>We use the information and data we collect to provide and improve the Platform, personalize your
                        experience, and communicate with you about your account and our services. </p>
                    <h5>2.2 Communication:</h5>
                    <p>We may use your email address to send you important information about your account, updates to
                        the Platform, or other relevant announcements. </p>
                    <h5>3. Data Sharing and Disclosure </h5>
                    <h5>3.1 Third-Party Service Providers:</h5>
                    <p>We may share your personal information with third-party service providers who assist us in
                        operating the Platform, such as hosting providers, analytics providers, and customer support
                        services. These third parties are authorized to use your personal information only as necessary
                        to provide services to us. </p>
                    <h5>3.2 Legal Compliance:</h5>
                    <p> We may disclose your personal information if required to do so by law or in response to valid
                        legal requests, such as court orders or subpoenas. </p>
                    <h5>4. Data Security </h5>
                    <p>We take reasonable measures to protect the confidentiality and security of your personal
                        information, including encryption, access controls, and regular security assessments. However,
                        please be aware that no method of transmission over the internet or electronic storage is
                        completely secure, and we cannot guarantee absolute security. </p>
                    <h5>5. Your Rights and Choices </h5>
                    <h5>5.1 Access and Correction:</h5>
                    <p>You have the right to access and update your personal information stored in your account profile.
                        You may also request corrections to any inaccuracies in your information.</p>
                    <h5>5.2 Data Deletion: </h5>
                    <p>When you delete your account on the platform, data you’ve uploaded as well as your email and
                        account information will be deleted.</p>
                    <h5>6. Changes to This Privacy Policy </h5>
                    <p>We may update this Privacy Policy from time to time to reflect changes in our data practices or
                        legal requirements. We will notify you of any material changes by posting the updated Privacy
                        Policy on the Platform or by other means as required by law. </p>
                    <h5>7. Contact Us </h5>
                    <p>If you have any questions or concerns about this Privacy Policy or our data practices, please
                        contact us at <a href="mailto:aip@summalinguae.com">aip@summalinguae.com</a></p>
            </Container>
)
    ;
};